import React from "react";
import { useTranslation } from "react-i18next";
import Link from "next/link";

import { CImage } from "../src/components/atoms";
import { getClass } from "../src/helpers/formatHelpers";
import Image from "next/image";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={`${getClass("footer", true)}`}>
      <div
        className={`wrapper mx-auto ${
          process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
          process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
          process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
            ? "py-2"
            : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
            ? "py-2"
            : "py-5"
        }`}>
        <div className="flex flex-row items-center justify-center">
          <Link prefetch={false} href="/">
            <a
              className="sm:hidden block"
              style={{
                height:
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 50
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 45
                    : 34,
              }}>
              <CImage
                source={process.env.NEXT_PUBLIC_APP_LOGO}
                width={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 240
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 200
                    : 120
                }
                height={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 50
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 45
                    : 34
                }
                layout="fixed"
                alt={`${process.env.NEXT_PUBLIC_APP_NAME} logo`}
              />
            </a>
          </Link>
          <Link prefetch={false} href="/">
            <a
              className="sm:block hidden"
              style={{
                height:
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 70
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 70
                    : 49,
              }}>
              <CImage
                source={process.env.NEXT_PUBLIC_APP_LOGO}
                width={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 330
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 220
                    : 182
                }
                height={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 70
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 70
                    : 49
                }
                layout="fixed"
                alt={`${process.env.NEXT_PUBLIC_APP_NAME} logo`}
              />
            </a>
          </Link>
        </div>
      </div>
      <div className={`border-t ${getClass("border-grayBackground")} py-2`}>
        <div
          className="wrapper mx-auto"
          style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="flex md:flex-row flex-col-reverse py-2 items-center justify-between">
            <div className="flex-1 flex flex-row md:my-0 my-4 items-center sm:order-1 order-3">
              <div
                className={`text-xs font-regular ${getClass(
                  "text-gray2-white",
                  true
                )} px-4`}>
                {t("key_web_copyright")}
              </div>
            </div>
            {process.env.NEXT_PUBLIC_APP_NAME == "Festyful" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/festyfulcom">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/festyfulcom">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://x.com/festyful_com">
                      <Image
                        src="/icons/ic_twitter_circle.svg"
                        width={18}
                        height={18}
                        alt="twitter"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@festyfulcom">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={18}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@festyfulcom">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={18}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "Vybeful" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/vybefulcom">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/vybefulcom">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "Cocktayl" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/cocktayl.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/cocktayl.co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@cocktayl.co">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@cocktaylco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/rooftopclub.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/rooftopclub.co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@rooftopclub.co">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@rooftopclubco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/castlepedia.org">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/castlepedia">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "Parkful" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/parkful.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/parkful_co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/zoopedia.org">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/zoopediaorg">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "AparthotelClub" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/AparthotelClub">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/apart_hotel_club_com">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/luxresortclubcom">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/luxresortclub">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "SpaClub" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/spaclub.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/spaclub.co">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@spaclub.co">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@spaclubco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "Beachful" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/beachful.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/beachfulco">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.tiktok.com/@beachfulco">
                      <Image
                        src="/icons/ic_tiktok_circle.svg"
                        width={19}
                        height={18}
                        alt="tiktok"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.youtube.com/@beachfulco">
                      <Image
                        src="/icons/ic_youtube_circle.svg"
                        width={19}
                        height={18}
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/prefabworld.co">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/prefabworldco">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" && (
              <div className="flex flex-1 justify-center sm:order-2 order-3">
                <div className="flex flex-row items-center">
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.facebook.com/worldcasino.org">
                      <Image
                        src="/icons/ic_facebook_circle.svg"
                        width={18}
                        height={18}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://www.instagram.com/worldcasino.org1/">
                      <Image
                        src="/icons/ic_instagram_circle.svg"
                        width={19}
                        height={18}
                        alt="instagram"
                      />
                    </a>
                  </div>
                  <div className="px-1" style={{ height: 18 }}>
                    <a href="https://x.com/worldcasinoorg">
                      <Image
                        src="/icons/ic_twitter_circle.svg"
                        width={18}
                        height={18}
                        alt="twitter"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div className="flex-1 flex flex-row items-center justify-end order-2">
              {/* <div className="md:px-4 px-1">
                <Link prefetch={false} href="/help-center/">
                  <a className={`text-xs font-regular ${getClass('text-gray2-white', true)}`}>
                    {t("key_web_help_center")}
                  </a>
                </Link>
              </div> */}
              {/* <div className="md:px-4 px-1">
                <a
                  className={`text-xs font-regular ${getClass('text-gray2-white', true)}`}
                  href="https://drive.google.com/drive/folders/1wsCVka-wEssR_yBk6jWXQG4xuPK8zbgI">
                  {t("key_web_media_kit")}
                </a>
              </div> */}
              {process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" && (
                <Link prefetch={false} href="/gambling-help/">
                  <a
                    className={`text-xs md:px-4 px-1 font-regular ${getClass(
                      "text-gray2-white",
                      true
                    )}`}>
                    Gambling Help
                  </a>
                </Link>
              )}
              <Link prefetch={false} href="/cookie-policy/">
                <a
                  className={`text-xs md:px-4 px-1 font-regular ${getClass(
                    "text-gray2-white",
                    true
                  )}`}>
                  {t("key_web_cookies")}
                </a>
              </Link>
              <Link prefetch={false} href="/legal/">
                <a
                  className={`text-xs md:px-4 px-1 font-regular ${getClass(
                    "text-gray2-white",
                    true
                  )}`}>
                  {t("key_web_legal")}
                </a>
              </Link>
              <Link prefetch={false} href="/sitemap.xml/">
                <a
                  className={`text-xs md:px-4 px-1 font-regular ${getClass(
                    "text-gray2-white",
                    true
                  )}`}>
                  {t("key_web_sitemap")}
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
